<template>
  <div class="pt-3">
    <v-row class="mt-4 mb-0 d-flex justify-end">
      <v-col cols="12" sm="12" md="6" class="py-0 d-flex">
        <v-select
          dense
          hide-details
          class="mr-4"
          v-model="anio"
          :items="listAnios"
          @change="cambioAnio"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Año"
        ></v-select>
        <!--<v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-4 pt-0"
              dense
              hide-details
              v-model="dateRangeText"
              label="Filtro por fechas"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" range locale="es-es" :first-day-of-week="1"></v-date-picker>
        </v-menu>-->
        <v-select
          dense
          multiple
          v-model="areas"
          :items="itemsAreas"
          no-data-text="No se encontró información"
          :return-object="false"
          item-text="text"
          item-value="value"
          label="Área"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllAreas" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAllAreas }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="areas.length == itemsAreas.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else-if="areas.length > 1">
              <span class="grey--text text-caption" v-if="index === 0">({{ areas.length }} Items)</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.value }}</span>
            </template>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :height="450"
      :items="proyectosFilters"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">PROYECTOS</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" vertical></v-divider>
          <v-tooltip bottom v-auth-acl="'gh-proy-mis_proy-export'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" fab dark small @click="exportarbase" v-bind="attrs" v-on="on">
                <v-icon dark>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Exportar BD</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.proyecto`]="{ item }">
        <span>{{ item.proyecto }} </span>
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <v-col cols="12" class="d-flex py-0 px-0">
          <v-tooltip bottom v-auth-acl="'gh-proy-mis_proy-delete'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-1" fab icon x-small color="red" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                <v-icon small dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip bottom v-auth-acl="'gh-proy-mis_proy-edit'" v-if="item.editar == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab icon x-small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="editProyecto(item)">
                <v-icon small dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.estado == 2" v-auth-acl="'gh-proy-mis_proy-sendemail'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab icon x-small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="fileSend(item)">
                <v-icon small dark>
                  mdi-send
                </v-icon>
              </v-btn>
            </template>
            <span>Enviar</span>
          </v-tooltip>
          <v-tooltip bottom v-auth-acl="'gh-proy-mis_proy-view'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-1" fab icon x-small color="primary" v-bind="attrs" v-on="on" @click="ganttView(item)">
                <v-icon small dark>
                  mdi-file-tree
                </v-icon>
              </v-btn>
            </template>
            <span>Ver Gantt</span>
          </v-tooltip>
          <v-tooltip bottom v-auth-acl="'gh-proy-mis_proy-view'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab icon x-small color="primary" v-bind="attrs" v-on="on" @click="viewProyecto(item)">
                <v-icon small dark>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </v-col>
      </template>
      <template v-slot:[`item.fechainicio`]="{ item }">
        {{
          item.fechainicio
            .split('-')
            .reverse()
            .join('-')
        }}
      </template>
      <template v-slot:[`item.fechafin`]="{ item }">
        {{
          item.fechafin
            .split('-')
            .reverse()
            .join('-')
        }}
      </template>
    </v-data-table>
    <ProyectoView
      v-auth-acl="'gh-proy-mis_proy-view'"
      v-if="isProyectoView"
      ref="refproyectoview"
      :object="objectViewProyecto"
      :objtable="objectViewProyectoTable"
      :dialog="dialogProView"
      @closeProyectoView="closeProyectoView"
    ></ProyectoView>
    <ProyectoFormulario
      v-auth-acl="'gh-proy-mis_proy-view'"
      v-if="isProyectoEdit"
      ref="refproyectoedit"
      :id="idEdit"
      :object="objectEditProyecto"
      :objtable="objectEditProyectoTable"
      :dialog="dialogProEdit"
      @closeProyectoEdit="closeProyectoEdit"
    ></ProyectoFormulario>
    <GanttView
      v-auth-acl="'gh-proy-mis_proy-view'"
      v-if="isGanttView"
      ref="refelastic"
      :obj="headerTask"
      :dialog="dialogGanttView"
      @closeGanttView="closeGanttView"
    ></GanttView>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizProyectoService from '@/giplan/proyectos/matriz/services/MatrizProyectoService';
import moment from 'moment';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import ProyectoView from '../components/ProyectoView';
import ProyectoFormulario from '../components/ProyectoFormulario';
import GanttView from '@/giplan/proyectos/matriz/components/GanttView';
import Swal from 'sweetalert2';
import { decryptAES } from '@/components4x/utils/utils4x';

Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: 'new'
  },
  name: 'MatrizProyectoGeneral',
  components: {
    ProyectoView,
    ProyectoFormulario,
    GanttView
  },
  data: () => ({
    showLoading: false,
    search: '',
    menuDateRange: false,
    dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dateRangeText: `${moment().format('YYYY-MM-DD')} ~ ${moment().format('YYYY-MM-DD')}`,
    objectViewProyecto: {},
    objectViewProyectoTable: {},
    objectEditProyecto: {},
    objectEditProyectoTable: {},
    idEdit: '',
    headers: [
      { text: 'N°', value: 'numero', width: '70', class: 'primary' },
      { text: 'ACCIONES', value: 'accion', width: '160', class: 'primary' },
      { text: 'PROYECTO', value: 'proyecto', width: '300', class: 'primary' },
      //{ text: 'GERENCIA', value: 'gerencia', width: '200', class: 'primary' },
      { text: 'ÁREA', value: 'area', width: '250', class: 'primary' },
      { text: 'PRESUPUESTO', value: 'presupuesto', width: '200', class: 'primary', align: 'end' },
      { text: '% AVANCE', value: 'avance', width: '200', class: 'primary' },
      { text: 'FECHA INICIO', value: 'fechainicio', width: '250', class: 'primary' },
      { text: 'FECHA FIN', value: 'fechafin', width: '200', class: 'primary' },
      { text: 'FECHA CREACIÓN', value: 'fechacreacion', width: '250', class: 'primary' },
      { text: 'FECHA MODIFICACIÓN', value: 'fechamodificacion', width: '200', class: 'primary' }
    ],
    proyectos: [],
    dialogProView: false,
    dialogProEdit: false,
    dialogGanttView: false,
    isGanttView: false,
    isProyectoView: false,
    isProyectoEdit: false,
    headerTask: {
      name: '',
      start: 0,
      end: 0,
      dateStart: 0,
      dateEnd: 0,
      percent: 0,
      budget: 0,
      typex: 'proyecto'
    },
    areas: [],
    itemsAreas: [],
    anio: new Date().getFullYear().toString(),
    listAnios: []
  }),
  computed: {
    checkAllAreas() {
      return (this.areas?.length ?? 0) === this.itemsAreas.length;
    },
    checkSomeAreas() {
      return (this.areas?.length ?? 0) > 0 && !this.checkAllAreas;
    },
    iconCheckboxAllAreas() {
      if (this.checkAllAreas) return 'mdi-close-box';
      if (this.checkSomeAreas) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    proyectosFilters() {
      if (this.areas.length > 0 && this.areas.length != this.itemsAreas.length) {
        return this.proyectos
          .filter((el) => {
            let exist = this.areas.findIndex((el2) => el2 == el.area);
            if (exist != -1) return true;
            return false;
          })
          .map((el, index) => {
            return { ...el, numero: index + 1 };
          });
      } else {
        return this.proyectos;
      }
    }
  },
  watch: {
    async dateRange(val) {
      if (val.length === 1) {
        this.dateRangeText = `${val[0]} ~ `;
      }
      if (val.length === 2) {
        const min = Date.parse(val[0]) < Date.parse(val[1]) ? val[0] : val[1];
        const max = Date.parse(val[0]) > Date.parse(val[1]) ? val[0] : val[1];
        this.dateRangeText = `${min} ~ ${max}`;
        this.areas = [];
        await this.initialize();
        await this.cargarAreas();
      }
    }
  },
  methods: {
    async fileSend(item) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea enviar la notificación?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const idproyecto = item.idproyecto;
        const equiposemail = [];
        const equipos = [];
        const resp = await this.matrizProyectoService.get().execResource('viewproyecto', {
          idproyecto: idproyecto
        });
        resp[0].equiposjson.forEach((el) => {
          equiposemail.push(el.email);
        });
        equipos.push({
          email: equiposemail.filter((el, index, array) => index === array.indexOf(el)),
          subject: 'NOTIFICACIÓN PROYECTO',
          content:
            'Este mensaje es para informarle que usted forma parte del equipo del proyecto "' +
            resp[0].titulo.toLowerCase() +
            '"'
        });
        const restable = await this.matrizProyectoService.get().execResource('gantt', { idproyecto: idproyecto });
        const tareas = restable.filter((element) => element.idtype == 'T');
        const totalparticipantes = [];
        tareas.forEach((el) => {
          let support = [];
          let responsibles = [];
          if (el.idsupport.length > 0) {
            el.idsupport.forEach((els) => {
              support.push(els.email);
            });
            totalparticipantes.push({
              email: support,
              subject: 'NOTIFICACIÓN PROYECTO',
              content:
                'Este mensaje es para informarle que usted forma parte del apoyo de la tarea "' +
                el.label +
                '"' +
                ' del proyecto " ' +
                resp[0].titulo +
                '"'
            });
          }
          if (el.idresponsible.length > 0) {
            el.idresponsible.forEach((els) => {
              responsibles.push(els.email);
            });
            totalparticipantes.push({
              email: responsibles,
              subject: 'NOTIFICACIÓN PROYECTO',
              content:
                'Este mensaje es para informarle que usted forma esta asignado en la tarea "' +
                el.label +
                '"' +
                ' del proyecto " ' +
                resp[0].titulo +
                '"'
            });
          }
        });
        totalparticipantes.push(equipos[0]);
        this.matrizProyectoService.post().execResource('sendemail', {
          participantes: totalparticipantes
        });
        const res = await this.matrizProyectoService.post().execResource('filesend', {
          id: idproyecto
        });
        this.showLoading = false;
        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.initialize();
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async exportarbase() {
      this.showLoading = true;
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MISPROYECTOS-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      await this.matrizProyectoService.downloadResource(
        'exportar',
        {
          token: token,
          subarea: this.areas.join('|').replaceAll('&', '%26'),
          anio: this.anio
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async initialize() {
      this.showLoading = true;

      const res = await this.matrizProyectoService.get().execResource('listaproyecto', {
        subarea: this.areas.join('|').replaceAll('&', '%26'),
        anio: this.anio
      });
      if (res.length > 0) {
        this.proyectos = [];
        res.forEach((el, index) => {
          this.proyectos.push({
            numero: index + 1,
            proyecto: el.titulo,
            gerencia: el.gerencia,
            area: el.area,
            estado: el.estado,
            fechainicio: el.fechainicio,
            fechafin: el.fechafin,
            fechacreacion: moment(el.fechacreacion).format('DD-MM-YYYY HH:mm'),
            fechamodificacion: moment(el.fechaalter).format('DD-MM-YYYY HH:mm'),
            presupuesto: el.presupuesto,
            editar: parseInt(el.editar),
            avance: el.avanceproyecto,
            idproyecto: el.idfichatecnicapy
          });
        });
      } else {
        this.proyectos = [];
      }

      this.showLoading = false;
    },
    async ganttView(item) {
      this.headerTask = {
        name: item.proyecto,
        start: this.convertDate(item.fechainicio),
        end: this.convertDate(item.fechafin),
        dateStart: moment(item.fechainicio).format('DD-MM-YYYY'),
        dateEnd: moment(item.fechafin).format('DD-MM-YYYY'),
        percent: parseInt(item.avance),
        budget: 0,
        avance: item.avance + '%',
        typex: 'proyecto'
      };

      this.isGanttView = true;
      this.showLoading = true;

      const res = await this.matrizProyectoService.get().execResource('gantt', { idproyecto: item.idproyecto });
      this.dialogGanttView = true;

      if (res.length > 0) {
        res.forEach((el) => {
          let pid = 0;

          if (el.parents.length === 0) pid = 0;
          if (el.parents.length === 1) pid = el.parents[0];
          if (el.parents.length === 2) pid = el.parents[1];

          let liResponsible = '';
          let ulResponsibles = '';
          let liSupport = '';
          let ulSupport = '';
          let style = {};

          if (el.idresponsible !== null) {
            el.idresponsible.forEach((el) => (liResponsible += `<li>${el.name}</li>`));
            ulResponsibles = `<ul>${liResponsible}</ul>`;
          }

          if (el.idsupport !== null) {
            el.idsupport.forEach((el) => (liSupport += `<li>${el.name}</li>`));
            ulSupport = `<ul>${liSupport}</ul>`;
          }

          if (el.type === 'etapa') {
            style = {
              base: {
                fill: '#023E7D',
                stroke: '#023E7D'
              },
              diagonal: {
                fill: '#cccccc'
              }
            };
          }
          if (el.type === 'actividad') {
            style = {
              base: {
                fill: '#1B4332',
                stroke: '#1B4332'
              },
              diagonal: {
                fill: '#cccccc'
              }
            };
          }
          if (el.type === 'tarea') {
            style = style = {
              base: {
                fill: '#40916C',
                stroke: '#40916C'
              },
              diagonal: {
                fill: '#cccccc'
              }
            };
          }
          this.$refs.refelastic.tasks.push({
            idempresa: el.idempresa,
            idfichatecnicapy: el.idfichatecnicapy,
            id: el.id,
            label: el.label,
            user: ulResponsibles,
            idresponsible: el.idresponsible,
            support: ulSupport,
            idsupport: el.idsupport,
            dateStart: moment(el.dateStart).format('DD-MM-YYYY'),
            dateEnd: moment(el.dateEnd).format('DD-MM-YYYY'),
            start: this.convertDate(el.dateStart),
            endTime: this.convertDate(el.dateEnd),
            duration: this.convertDate(el.dateEnd) - this.convertDate(el.dateStart),
            percent: el.avance,
            avance: `${parseFloat(el.avance).toFixed(0)}%`,
            durationContext: 'h',
            type: 'task',
            typex: el.type,
            ex: el.parents,
            parentId: pid,
            budget: el.budget,
            idtype: el.idtype,
            style: style
          });
        });
      } else {
        this.dialogGanttView = false;
        this.isGanttView = false;
      }

      this.showLoading = false;
    },
    closeGanttView() {
      this.dialogGanttView = false;
      this.isGanttView = false;
      this.headerTask = {
        name: '',
        start: 0,
        end: 0,
        dateStart: 0,
        dateEnd: 0,
        percent: 0,
        budget: 0,
        typex: 'proyecto'
      };
    },
    closeProyectoView() {
      this.dialogProView = false;
      this.isProyectoView = false;
      this.objectViewProyecto = {
        objetivosjson: [],
        justificacionjson: [],
        equiposjson: [],
        gerencia: '',
        titulo: '',
        area: '',
        presupuesto: '',
        fechainicio: '',
        fechafin: ''
      };
    },
    closeProyectoEdit() {
      this.dialogProEdit = false;
      this.isProyectoEdit = false;
      this.objectEditProyecto = {
        objetivosjson: [],
        justificacionjson: [],
        equiposjson: [],
        gerencia: '',
        titulo: '',
        area: '',
        presupuesto: '',
        fechainicio: '',
        fechafin: ''
      };
    },
    async viewProyecto(item) {
      const idproyecto = item.idproyecto;
      this.objectViewProyectoTable = {
        name: item.proyecto,
        start: this.convertDate(item.fechainicio),
        end: this.convertDate(item.fechafin),
        dateStart: moment(item.fechainicio).format('DD-MM-YYYY'),
        dateEnd: moment(item.fechafin).format('DD-MM-YYYY'),
        percent: parseInt(this.avance),
        budget: item.presupuesto,
        avance: item.avance + '%',
        typex: 'proyecto'
      };
      this.isProyectoView = true;
      this.showLoading = true;
      this.objectViewProyecto = {
        objetivosjson: [],
        justificacionjson: [],
        equiposjson: [],
        gerencia: '',
        titulo: '',
        area: '',
        presupuesto: '',
        fechainicio: '',
        fechafin: ''
      };

      const res = await this.matrizProyectoService.get().execResource('viewproyecto', {
        idproyecto: idproyecto
      });

      this.objectViewProyecto = res[0];

      //------------------------------------------------------------------------------------
      const restable = await this.matrizProyectoService.get().execResource('gantt', { idproyecto: item.idproyecto });

      if (restable.length > 0) {
        restable.forEach((el) => {
          let pid = 0;

          if (el.parents.length === 0) pid = 0;
          if (el.parents.length === 1) pid = el.parents[0];
          if (el.parents.length === 2) pid = el.parents[1];

          let liResponsible = '';
          let ulResponsibles = '';
          let liSupport = '';
          let ulSupport = '';

          if (el.idresponsible !== null) {
            el.idresponsible.forEach((el) => (liResponsible += `<li>${el.name}</li>`));
            ulResponsibles = `<ul>${liResponsible}</ul>`;
          }

          if (el.idsupport !== null) {
            el.idsupport.forEach((el) => (liSupport += `<li>${el.name}</li>`));
            ulSupport = `<ul>${liSupport}</ul>`;
          }

          this.$refs.refproyectoview.tasks.push({
            idempresa: el.idempresa,
            idfichatecnicapy: el.idfichatecnicapy,
            id: el.id,
            label: el.label,
            user: ulResponsibles,
            idresponsible: el.idresponsible,
            support: ulSupport,
            idsupport: el.idsupport,
            dateStart: moment(el.dateStart).format('DD-MM-YYYY'),
            dateEnd: moment(el.dateEnd).format('DD-MM-YYYY'),
            start: this.convertDate(el.dateStart),
            endTime: this.convertDate(el.dateEnd),
            duration: this.convertDate(el.dateEnd) - this.convertDate(el.dateStart),
            percent: el.avance,
            avance: `${parseFloat(el.avance).toFixed(0)}%`,
            durationContext: 'h',
            type: 'task',
            typex: el.type,
            ex: el.parents,
            parents: el.parents,
            parentId: pid,
            budget: parseFloat(el.budget).toFixed(0),
            idtype: el.idtype
          });
        });
      } else {
        this.dialogProView = false;
        this.isProyectoView = false;
      }

      this.showLoading = false;
      this.dialogProView = true;
    },
    async editProyecto(item) {
      this.idEdit = item.idproyecto;

      this.objectEditProyectoTable = {
        name: item.proyecto,
        start: this.convertDate(item.fechainicio),
        end: this.convertDate(item.fechafin),
        dateStart: item.fechainicio
          .split('-')
          .reverse()
          .join('-'),
        dateEnd: item.fechafin
          .split('-')
          .reverse()
          .join('-'),
        percent: 0,
        budget: 0,
        typex: 'proyecto'
      };

      this.isProyectoEdit = true;
      this.showLoading = true;

      this.objectEditProyecto = {
        objetivosjson: [],
        justificacionjson: [],
        equiposjson: [],
        gerencia: '',
        titulo: '',
        area: '',
        presupuesto: '',
        fechainicio: '',
        fechafin: '',
        estadoproyecto: null
      };

      const res = await this.matrizProyectoService.get().execResource('viewproyecto', {
        idproyecto: this.idEdit
      });

      this.objectEditProyecto = res[0];

      //------------------------------------------------------------------------------------
      const restable = await this.matrizProyectoService.get().execResource('gantt', { idproyecto: item.idproyecto });

      if (restable.length > 0) {
        restable.forEach((el) => {
          let pid = 0;

          if (el.parents.length === 0) pid = null;
          if (el.parents.length === 1) pid = el.parents[0];
          if (el.parents.length === 2) pid = el.parents[1];

          let liResponsible = '';
          let ulResponsibles = '';
          let liSupport = '';
          let ulSupport = '';

          if (el.idresponsible !== null) {
            el.idresponsible.forEach((el) => (liResponsible += `<li>${el.name}</li>`));
            ulResponsibles = `<ul>${liResponsible}</ul>`;
          }

          if (el.idsupport !== null) {
            el.idsupport.forEach((el) => (liSupport += `<li>${el.name}</li>`));
            ulSupport = `<ul>${liSupport}</ul>`;
          }
          this.$refs.refproyectoedit.tasks.push({
            idempresa: el.idempresa,
            idfichatecnicapy: el.idfichatecnicapy,
            id: el.id,
            label: el.label,
            entregable: el.descripcionentregable,
            usuario: ulResponsibles,
            idresponsible: el.idresponsible === null ? [] : el.idresponsible,
            support: ulSupport,
            idsupport: el.idsupport === null ? [] : el.idsupport,
            dateStart: el.dateStart,
            dateEnd: el.dateEnd,
            start: this.convertDate(el.dateStart),
            endTime: this.convertDate(el.dateEnd),
            duration: this.convertDate(el.dateEnd) - this.convertDate(el.dateStart),
            percent: el.avance,
            avance: `${parseFloat(el.avance).toFixed(0)}%`,
            durationContext: 'h',
            type: el.type,
            typex: el.type,
            ex: el.parents,
            parentId: pid,
            budget: parseFloat(el.budget).toFixed(0),
            idtype: el.idtype,
            level: el.level
          });
        });
      } else {
        this.dialogProEdit = false;
        this.isProyectoEdit = false;
      }

      this.$refs.refproyectoedit.tasks.splice(0, 1);

      this.showLoading = false;
      this.dialogProEdit = true;
    },
    async deleteItem({ idproyecto }) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Desea eliminar el proyecto.',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const restable = await this.matrizProyectoService.delete().execResource('eliminarproyecto', { id: idproyecto });
        this.showLoading = false;
        if (restable.status) {
          await this.alertDialog('success', restable.data);
          await this.initialize();
        } else {
          await this.alertDialog('error', restable.data);
        }
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    openDialogSubtask({ code, text, percent }) {
      this.dialogSubtask = true;
      this.subtaskId = code;
      this.messageDialog = `Añadir Subtarea [ ${text} ~ ${percent}% ]`;
    },
    closeAddSubtask() {
      this.subtaskName = '';
      this.subtaskDescription = '';
      this.subtaskPercent = 0;
      this.subtaskEvidence = null;
      this.subtaskId = '';
      this.messageDialog = '';
      this.dialogSubtask = false;
    },
    async aceptAddSubtask() {
      const res = await this.$validator.validateAll();
      console.log('validadte -> ', res);
    },
    aceptEditSubtask() {},
    increment() {
      if (parseFloat(this.subtaskPercent) < 100 && parseFloat(this.subtaskPercent) >= 0) {
        this.subtaskPercent++;
      }
    },
    decrement() {
      if (parseFloat(this.subtaskPercent) <= 100 && parseFloat(this.subtaskPercent) > 0) {
        this.subtaskPercent--;
      }
    },
    convertDate(date) {
      const hours = 0; // mañana 24 | hoy 0
      const stringDate = date.split('-');
      const currentYear = parseInt(stringDate[0]);
      const currentMonth = parseInt(stringDate[1]) - 1;
      const currentDay = parseInt(stringDate[2]);
      const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
      return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
    },
    selectAllAreas() {
      this.$nextTick(() => {
        if (this.checkAllAreas) {
          this.areas = [];
        } else {
          this.areas = this.itemsAreas.map((element) => {
            return element.value;
          });
        }
      });
    },
    async cargarAreas() {
      this.itemsAreas = [];

      this.proyectos.forEach((el) => {
        let exist = -1;
        exist = this.itemsAreas.findIndex((el2) => el2.value.replace('\t', '') == el.area.replace('\t', ''));
        if (exist == -1 && el.area != '') {
          this.itemsAreas.push({ value: el.area.replace('\t', ''), text: el.area.replace('\t', '') });
        }
      });
      this.itemsAreas.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
      this.areas = this.itemsAreas.map((el) => el.value);
    },
    async intervaloFechas() {
      let currentYear = moment().year(); // año actual
      let currentMonth = moment().month(); // año actual
      let endMonthDays = moment([currentYear, currentMonth]).daysInMonth(); // número de días en el mes
      this.dateRange = [
        moment(currentYear + '-' + currentMonth + '-' + 1).format('YYYY-MM-DD'),
        moment(currentYear + '-' + currentMonth + '-' + endMonthDays).format('YYYY-MM-DD')
      ];
      this.dateRange = [
        moment([currentYear, currentMonth, 1]).format('YYYY-MM-DD'),
        moment([currentYear, currentMonth, endMonthDays]).format('YYYY-MM-DD')
      ];
    },
    async cargarAnios() {
      this.listAnios = [];
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.matrizProyectoService.get().execResource('cargarAnios', {
        idempresa: emp
      });
      this.listAnios = res.map((el) => {
        return { value: el.anio, text: el.anio };
      });
      this.showLoading = false;
    },
    async cambioAnio() {
      this.areas = [];
      await this.initialize();
      await this.cargarAreas();
    }
  },
  filters: {
    convertSizeFiles(size) {
      const bytes = parseInt(size);
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '';
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      if (i === 0) return `${bytes} ${sizes[i]}`;
      const res = (bytes / Math.pow(1024, i)).toFixed(2);
      return `(${res} ${sizes[i]})`;
    }
  },
  async mounted() {
    this.$validator.localize('es', this.dictes);
  },
  async created() {
    this.showLoading = true;
    this.matrizProyectoService = this.$httpService(new MatrizProyectoService(), this);
    //await this.intervaloFechas();
    await this.cargarAnios();
    await this.initialize();
    await this.cargarAreas();
    this.showLoading = false;
  }
};
</script>
<style lang="scss">
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important;
}
</style>
