<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable persistent width="1500px" @close="close">
        <v-card>
          <!---------------------------------------------------------------------------------------------------------->
          <v-card-text class="pa-0 pt-1">
            <v-stepper v-model="stepper" elevation="0" alt-labels>
              <v-stepper-header>
                <v-stepper-step complete editable edit-icon="mdi-check" step="1" class="py-1 px-0">
                  Acta
                </v-stepper-step>
                <v-divider class="mx-1 mt-6"></v-divider>
                <v-stepper-step complete editable edit-icon="mdi-check" step="2" class="py-1 px-0">
                  Etapas
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items class="py-0">
                <v-stepper-content step="1" class="py-0">
                  <v-row class="pt-10">
                    <v-col cols="6" sm="6" class="py-0">
                      <v-text-field dense v-model="object.titulo" label="Titulo del Proyecto" readonly></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" class="py-0">
                      <v-text-field dense v-model="object.presupuesto" label="Presupuesto" readonly></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-text-field dense v-model="object.gerencia" label="Gerencia" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-text-field dense v-model="object.area" label="Área" readonly></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-text-field
                        dense
                        label="Fecha Inicio"
                        readonly
                        :value="
                          object.fechainicio
                            .split('-')
                            .reverse()
                            .join('-')
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-text-field
                        dense
                        label="Fecha Fin"
                        readonly
                        :value="
                          object.fechafin
                            .split('-')
                            .reverse()
                            .join('-')
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" class="py-0 mb-4">
                      <div>
                        <v-data-table
                          dense
                          :headers="cObjetivo"
                          :items="object.objetivosjson"
                          :items-per-page="-1"
                          class="border"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <div class="primary w-100 d-flex justify-space-between px-2  py-1 white--text body-1">
                              OBJETIVOS
                            </div>
                          </template>
                          <template v-slot:no-data>
                            <span>No se encontró información</span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="py-0 mb-4">
                      <div>
                        <v-data-table
                          dense
                          :headers="cJustificacion"
                          :items="object.justificacionjson"
                          :items-per-page="-1"
                          class="border"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <div class="primary w-100 d-flex justify-space-between px-2  py-1 white--text body-1">
                              JUSTIFICACIÓN
                            </div>
                          </template>
                          <template v-slot:no-data>
                            <span>No se encontró información</span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                      <div>
                        <v-data-table
                          dense
                          :headers="cEquipo"
                          :items="object.equiposjson"
                          :items-per-page="-1"
                          class="border"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <div class="primary w-100 d-flex justify-space-between px-2  py-1 white--text body-1">
                              EQUIPO DEL PROYECTO
                            </div>
                          </template>
                          <template v-slot:[`item.tipo`]="{ item }">
                            <v-chip small v-if="item.tipo == 'SP'" color="green darken-2" dark>SPONSOR</v-chip>
                            <v-chip small v-if="item.tipo == 'LI'" color="orange darken-2" dark>LIDER</v-chip>
                            <v-chip small v-if="item.tipo == 'ET'" color="red darken-2" dark>EQUIPO TÉCNICO</v-chip>
                            <v-chip small v-if="item.tipo == 'PA'" color="yellow darken-2" dark>PARTICIPANTES</v-chip>
                          </template>
                          <template v-slot:no-data>
                            <span>No se encontró información</span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mb-4 d-flex justify-end pr-4">
                    <v-btn small color="primary" @click="stepper = 2">
                      Siguiente
                    </v-btn>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <table-gantt-elastic
                    :options="options"
                    :tasks="tasks"
                    :view-tasks="true"
                    :view-chart="false"
                    @tasks-changed="tasksUpdate"
                    @options-changed="optionsUpdate"
                    @dynamic-style-changed="styleUpdate"
                  >
                  </table-gantt-elastic>
                  <v-row class="mb-4 d-flex justify-start mt-4 pl-4">
                    <v-btn small color="primary" @click="stepper = 1">
                      Anterior
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" text @click="$emit('closeProyectoView')">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import TableGanttElastic from '@/components4x/table/TableGanttElastic';

function convertDate(date) {
  const hours = 0; // mañana 24 | hoy 0
  const stringDate = date.split('-');
  const currentYear = parseInt(stringDate[0]);
  const currentMonth = parseInt(stringDate[1]) - 1;
  const currentDay = parseInt(stringDate[2]);
  const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
  return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
}

let options = {
  taskMapping: {
    progress: 'percent'
  },
  maxRows: 100,
  maxHeight: 500,
  title: {
    label: 'Your etapa title as html (link or whatever...)',
    html: false
  },
  row: {
    height: 24
  },
  calendar: {
    hour: {
      display: true
    }
  },
  chart: {
    progress: {
      bar: false
    },
    expander: {
      display: false
    }
  },
  taskList: {
    percent: 100,
    expander: {
      straight: true
    },
    columns: [
      {
        id: 1,
        label: 'Nombre de tarea',
        value: 'label',
        width: 240,
        expander: true,
        html: true,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 2,
        label: 'Asignado a',
        value: 'user',
        width: 150,
        html: true,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 3,
        label: 'Apoyo',
        value: 'support',
        width: 150,
        html: true,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 4,
        label: 'Presupuesto',
        value: 'budget',
        width: 100,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 5,
        label: 'Tipo',
        value: 'typex',
        width: 77,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 6,
        label: 'Fecha inicio',
        value: 'dateStart',
        width: 100,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold',
            'text-align': 'center',
            width: '100%'
          },
          'task-list-item-value-container': {
            'text-align': 'center',
            width: '100%'
          }
        }
      },
      {
        id: 7,
        label: 'Fecha fin',
        value: 'dateEnd',
        width: 100,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold',
            'text-align': 'center',
            width: '100%'
          },
          'task-list-item-value-container': {
            'text-align': 'center',
            width: '100%'
          }
        }
      }
    ]
  },
  locale: {
    name: 'es',
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    weekStart: 1,
    yearStart: 4,
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
    ordinal: (n) => `${n}º`,
    relativeTime: {
      future: 'en %s',
      past: '%s hace',
      s: 'hace unos segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un dia',
      dd: '%d dias',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años'
    }
  }
};

export default {
  name: 'ProyectoView',
  components: {
    tableGanttElastic: TableGanttElastic
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    object: {
      type: Object,
      default: () => ({
        objetivosjson: [],
        justificacionjson: [],
        equiposjson: [],
        gerencia: '',
        titulo: '',
        area: '',
        presupuesto: '',
        fechainicio: '',
        fechafin: ''
      })
    },
    objtable: {
      type: Object,
      default: () => ({
        name: '',
        start: convertDate(moment().format('YYYY-MM-DD')),
        end: convertDate(
          moment()
            .add(1, 'days')
            .format('YYYY-MM-DD')
        ),
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        percent: 0,
        budget: 0,
        typex: 'proyecto'
      })
    }
  },
  data: () => ({
    cObjetivo: [
      { text: 'N°', align: 'start', value: 'index', width: '50' },
      { text: 'Descripcion', value: 'descripcion', width: '200' }
    ],
    cJustificacion: [
      { text: 'N°', align: 'start', value: 'index', width: '50' },
      { text: 'Descripción', value: 'descripcion', width: '200' }
    ],
    cEquipo: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Dni',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Tipo', value: 'tipo' }
    ],
    stepper: 1,
    options,
    tasks: [],
    dynamicStyle: {}
  }),
  methods: {
    tasksUpdate(tasks) {
      this.tasks = tasks;
    },
    optionsUpdate(options) {
      this.options = options;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    },
    close() {
      this.$emit('closeProyectoView');
    }
  },
  async created() {
    this.tasks = [
      {
        id: 0,
        label: this.objtable.name,
        usuario: '',
        idresponsible: [],
        support: '',
        idsupport: [],
        dateStart: this.objtable.dateStart,
        dateEnd: this.objtable.dateEnd,
        start: this.objtable.start,
        duration: 0,
        durationContext: 'h',
        percent: 0,
        budget: this.objtable.budget,
        type: this.objtable.typex,
        typex: this.objtable.typex,
        idtype: 'E',
        level: 1
      }
    ];
  }
};
</script>
