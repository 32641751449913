var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',{staticClass:"gantt-elastic__chart-row-bar-wrapper gantt-elastic__chart-row-milestone-wrapper",style:(Object.assign({}, _vm.root.style['chart-row-bar-wrapper'],
    _vm.root.style['chart-row-milestone-wrapper'],
    _vm.task.style['chart-row-bar-wrapper']))},[(_vm.displayExpander)?_c('foreignObject',{staticClass:"gantt-elastic__chart-expander gantt-elastic__chart-expander--milestone",style:(Object.assign({}, _vm.root.style['chart-expander'],
      _vm.root.style['chart-expander--milestone'],
      _vm.task.style['chart-expander'])),attrs:{"x":_vm.task.x - _vm.root.state.options.chart.expander.offset - _vm.root.state.options.chart.expander.size,"y":_vm.task.y + (_vm.root.state.options.row.height - _vm.root.state.options.chart.expander.size) / 2,"width":_vm.root.state.options.chart.expander.size,"height":_vm.root.state.options.chart.expander.size}},[_c('expander',{attrs:{"tasks":[_vm.task],"options":_vm.root.state.options.chart.expander,"type":"chart"}})],1):_vm._e(),_c('svg',{staticClass:"gantt-elastic__chart-row-bar gantt-elastic__chart-row-milestone",style:(Object.assign({}, _vm.root.style['chart-row-bar'], _vm.root.style['chart-row-milestone'], _vm.task.style['chart-row-bar'])),attrs:{"x":_vm.task.x,"y":_vm.task.y,"width":_vm.task.width,"height":_vm.task.height,"viewBox":("0 0 " + (_vm.task.width) + " " + (_vm.task.height)),"xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.emitEvent('click', $event)},"mouseenter":function($event){return _vm.emitEvent('mouseenter', $event)},"mouseover":function($event){return _vm.emitEvent('mouseover', $event)},"mouseout":function($event){return _vm.emitEvent('mouseout', $event)},"mousemove":function($event){return _vm.emitEvent('mousemove', $event)},"mousedown":function($event){return _vm.emitEvent('mousedown', $event)},"mouseup":function($event){return _vm.emitEvent('mouseup', $event)},"mousewheel":function($event){return _vm.emitEvent('mousewheel', $event)},"touchstart":function($event){return _vm.emitEvent('touchstart', $event)},"touchmove":function($event){return _vm.emitEvent('touchmove', $event)},"touchend":function($event){return _vm.emitEvent('touchend', $event)}}},[_c('defs',[_c('clipPath',{attrs:{"id":_vm.clipPathId}},[_c('polygon',{attrs:{"points":_vm.getPoints}})])]),_c('polygon',{staticClass:"gantt-elastic__chart-row-bar-polygon gantt-elastic__chart-row-milestone-polygon",style:(Object.assign({}, _vm.root.style['chart-row-bar-polygon'],
        _vm.root.style['chart-row-milestone-polygon'],
        _vm.task.style['base'],
        _vm.task.style['chart-row-bar-polygon'])),attrs:{"points":_vm.getPoints}}),_c('progress-bar',{attrs:{"task":_vm.task,"clip-path":'url(#' + _vm.clipPathId + ')'}})],1),(_vm.root.state.options.chart.text.display)?_c('chart-text',{attrs:{"task":_vm.task}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }