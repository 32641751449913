class MatrizProyectoService {
  constructor() {}
  resources = () => ({
    matriz: {
      uri: `/v1/proyectos`,
      method: ['get']
    },
    exportarproyectos: {
      uri: `/v1/exportarproyectos`,
      method: ['get']
    },
    exportar: {
      uri: `/v1/proyectos/exportar`,
      method: ['get']
    },
    comentario: {
      uri: `/v1/proyectos/comentario`,
      method: ['put']
    },
    proyecto: {
      uri: `/v1/proyectos/actualizar`,
      method: ['put']
    },
    subirevidencia: {
      uri: `/v1/proyectos/fichero`,
      method: ['post'],
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    filesend: {
      uri: `/v1/proyecto/cambiarestadoguardado`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    descargarevidencia: {
      uri: `/v1/proyectos/descargarevidencia`,
      method: ['get']
    },
    viewacta: {
      uri: `/v1/actas/detalle`,
      method: ['get']
    },
    listaproyecto: {
      uri: `/v1/proyectos/all`,
      method: ['get']
    },
    viewproyecto: {
      uri: `/v1/proyectos/proyecto`,
      method: ['get']
    },
    gantt: {
      uri: `/v1/proyectos/etapas`,
      method: ['get']
    },
    fecha: {
      uri: `/v1/semana`,
      method: ['get']
    },
    eliminarproyecto: {
      uri: `/v1/proyecto/eliminar`,
      method: ['delete']
    },
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    eliminarEntregable: {
      uri: `/v1/proyectos/eliminarEntregable`,
      method: ['delete']
    },
    sendemail: {
      uri: `/v1/notificate/comites`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    cargarAnios: {
      uri: `/v1/proyectos/anios`,
      method: ['get']
    }
  });
}

export default MatrizProyectoService;
