var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gantt-elastic__main-view",style:(Object.assign({}, _vm.root.style['main-view']))},[_c('div',{staticClass:"gantt-elastic__main-container-wrapper",style:({
      overflow: 'hidden',
      'border-top': '1px solid rgb(238, 238, 238)',
      'border-bottom': '1px solid rgb(238, 238, 238)'
      //height: root.state.options.height + 50 + 'px'
      //'overflow-x': 'scroll'
    })},[_c('div',{ref:"mainView",staticClass:"gantt-elastic__main-container",style:(Object.assign({}, _vm.root.style['main-container'],
        {width: _vm.root.state.options.clientWidth + 'px'}))},[_c('div',{staticClass:"gantt-elastic__container",style:(Object.assign({}, _vm.root.style['container'])),on:{"mousemove":_vm.mouseMove,"mouseup":_vm.mouseUp}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.root.state.options.taskList.display),expression:"root.state.options.taskList.display"}],ref:"taskList",staticClass:"gantt-elastic__task-list-container",style:(Object.assign({}, _vm.root.style['task-list-container'],
            {width: _vm.root.state.options.taskList.finalWidth + 'px'}))},[_c('task-list')],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }