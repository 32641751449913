<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable persistent @close="close">
        <v-card>
          <v-card-text>
            <gantt-elastic
              :options="options"
              :tasks="tasks"
              @tasks-changed="tasksUpdate"
              @options-changed="optionsUpdate"
              @dynamic-style-changed="styleUpdate"
            >
              <gantt-elastic-header slot="header" :options="optionsHeader"></gantt-elastic-header>
            </gantt-elastic>
            <div class="q-mt-md" />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" text @click="$emit('closeGanttView')">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import GanttElastic from '@/components4x/gantt/GanttElastic';
import Header from '@/components4x/gantt/Header';
import moment from 'moment';

function convertDate(date) {
  const hours = 0; // mañana 24 | hoy 0
  const stringDate = date.split('-');
  const currentYear = parseInt(stringDate[0]);
  const currentMonth = parseInt(stringDate[1]) - 1;
  const currentDay = parseInt(stringDate[2]);
  const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
  return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
}

let optionsHeader = {
  title: {
    label: 'Gantt',
    html: false
  },
  locale: {
    Now: 'Hoy',
    'X-Scale': 'Zoom-X',
    'Y-Scale': 'Zoom-Y',
    'Task list width': 'Lista de tareas',
    'Before/After': 'Expandir',
    'Display task list': 'Mostrar tareas'
  }
};

let options = {
  taskMapping: {
    progress: 'percent'
  },
  maxRows: 100,
  maxHeight: 500,
  title: {
    label: 'Your project title as html (link or whatever...)',
    html: false
  },
  row: {
    height: 24
  },
  calendar: {
    hour: {
      display: false
    },
    day: {
      display: true
    }
  },
  chart: {
    progress: {
      bar: false
    },
    expander: {
      display: true
    }
  },
  taskList: {
    expander: {
      straight: false
    },
    columns: [
      {
        id: 1,
        label: 'Nombre',
        value: 'label',
        width: 200,
        expander: true,
        html: true,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        },
        events: {
          click($event) {
            console.log($event);
          },
          contextmenu: ({ event, data, column }) => {
            console.log(event, data, column);
            this.dataRowList = Object.assign({}, data);
            this.$refs.menu.open(event);
          }
        }
      },
      {
        id: 2,
        label: 'Asignado a',
        value: 'user',
        width: 130,
        html: true,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 3,
        label: 'Fecha Inicio',
        value: 'dateStart',
        width: 100,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 4,
        label: 'Fecha Fin',
        value: 'dateEnd',
        width: 100,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 5,
        label: 'Tipo',
        value: 'typex',
        width: 100,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            color: 'white',
            'font-weight': 'bold'
          }
        }
      },
      {
        id: 6,
        label: 'Porcentaje',
        value: 'avance',
        width: 100,
        style: {
          'task-list-header-label': {
            'text-transform': 'uppercase',
            'text-align': 'center',
            color: 'white',
            'font-weight': 'bold',
            width: '100%'
          },
          'task-list-item-value-container': {
            'text-align': 'center',
            width: '100%'
          }
        }
      }
    ]
  },
  locale: {
    name: 'es',
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    weekStart: 1,
    yearStart: 4,
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
    ordinal: (n) => `${n}º`,
    relativeTime: {
      future: 'en %s',
      past: '%s hace',
      s: 'hace unos segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un dia',
      dd: '%d dias',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años'
    }
  }
};
export default {
  name: 'GanttView',
  components: {
    ganttElasticHeader: Header,
    ganttElastic: GanttElastic
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    obj: {
      type: Object,
      default: () => ({
        name: '',
        start: convertDate(moment().format('YYYY-MM-DD')),
        end: convertDate(
          moment()
            .add(1, 'days')
            .format('YYYY-MM-DD')
        ),
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        percent: 0,
        avance: '',
        budget: 0,
        typex: 'proyecto'
      })
    }
  },
  data: () => ({
    options,
    tasks: [],
    optionsHeader,
    dynamicStyle: {}
  }),
  methods: {
    tasksUpdate(tasks) {
      this.tasks = tasks;
    },
    optionsUpdate(options) {
      this.options = options;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    },
    close() {
      this.$emit('closeGanttView');
    }
  },
  created() {
    this.tasks = [
      {
        idempresa: '',
        idfichatecnicapy: '',
        id: 0,
        label: this.obj.name,
        user: '',
        idresponsible: '',
        support: '',
        idsupport: '',
        dateStart: this.obj.dateStart,
        dateEnd: this.obj.dateEnd,
        start: this.obj.start,
        endTime: this.obj.end,
        duration: this.obj.end - this.obj.start,
        percent: this.obj.percent,
        avance: this.obj.avance,
        durationContext: 'h',
        type: 'project',
        typex: this.obj.typex,
        ex: [],
        parentId: null,
        budget: this.obj.budget,
        idtype: '',
        style: {
          base: {
            fill: '#A80000',
            stroke: '#A80000'
          },
          diagonal: {
            fill: '#cccccc'
          }
        }
      }
    ];
  }
};
</script>
