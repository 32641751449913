var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gantt-elastic__main-view",style:(Object.assign({}, _vm.root.style['main-view']))},[_c('div',{staticClass:"gantt-elastic__main-container-wrapper",style:(Object.assign({}, _vm.root.style['main-container-wrapper'], {height: _vm.root.state.options.height + 'px'}))},[_c('div',{ref:"mainView",staticClass:"gantt-elastic__main-container",style:(Object.assign({}, _vm.root.style['main-container'],
        {width: _vm.root.state.options.clientWidth + 'px',
        height: _vm.root.state.options.height + 'px'}))},[_c('div',{staticClass:"gantt-elastic__container",style:(Object.assign({}, _vm.root.style['container'])),on:{"mousemove":_vm.mouseMove,"mouseup":_vm.mouseUp}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.root.state.options.taskList.display),expression:"root.state.options.taskList.display"}],ref:"taskList",staticClass:"gantt-elastic__task-list-container",style:(Object.assign({}, _vm.root.style['task-list-container'],
            {width: _vm.root.state.options.taskList.finalWidth + 'px',
            height: _vm.root.state.options.height + 'px'}))},[_c('task-list')],1),_c('div',{ref:"chartContainer",staticClass:"gantt-elastic__main-view-container",style:(Object.assign({}, _vm.root.style['main-view-container'])),on:{"mousedown":_vm.chartMouseDown,"touchstart":_vm.chartMouseDown,"mouseup":_vm.chartMouseUp,"touchend":_vm.chartMouseUp,"mousemove":function($event){$event.preventDefault();return _vm.chartMouseMove.apply(null, arguments)},"touchmove":function($event){$event.preventDefault();return _vm.chartMouseMove.apply(null, arguments)},"wheel":function($event){$event.preventDefault();return _vm.chartWheel.apply(null, arguments)}}},[_c('chart')],1)])]),_c('div',{ref:"chartScrollContainerVertical",staticClass:"gantt-elastic__chart-scroll-container gantt-elastic__chart-scroll-container--vertical",style:(Object.assign({}, _vm.root.style['chart-scroll-container'],
        _vm.root.style['chart-scroll-container--vertical'],
        _vm.verticalStyle)),on:{"scroll":_vm.onVerticalScroll}},[_c('div',{staticClass:"gantt-elastic__chart-scroll--vertical",style:({ width: '1px', height: _vm.root.state.options.allVisibleTasksHeight + 'px' })})])]),_c('div',{ref:"chartScrollContainerHorizontal",staticClass:"gantt-elastic__chart-scroll-container gantt-elastic__chart-scroll-container--horizontal",style:(Object.assign({}, _vm.root.style['chart-scroll-container'],
      _vm.root.style['chart-scroll-container--horizontal'],
      {marginLeft: _vm.getMarginLeft})),on:{"scroll":_vm.onHorizontalScroll}},[_c('div',{staticClass:"gantt-elastic__chart-scroll--horizontal",style:({ height: '1px', width: _vm.root.state.options.width + 'px' })})])])}
var staticRenderFns = []

export { render, staticRenderFns }