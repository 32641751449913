<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable persistent width="1500px" @close="close">
        <v-card>
          <v-card-text class="pa-0 pt-1">
            <v-stepper v-model="stepper" alt-labels>
              <v-stepper-header>
                <v-stepper-step :complete="stepper > 1" step="1" class="py-1 px-0">
                  Acta
                </v-stepper-step>
                <v-divider class="mx-1 mt-6"></v-divider>
                <v-stepper-step :complete="stepper > 2" step="2" class="py-1 px-0">
                  Etapas
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items class="py-0">
                <v-stepper-content step="1" class="py-0">
                  <v-row class="pt-10">
                    <v-col cols="12" sm="12" class="py-0">
                      <v-text-field
                        dense
                        v-model="proyectoTitulo"
                        label="Titulo del Proyecto"
                        clearable
                        clear-icon="mdi-close"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-text-field
                        dense
                        v-model="gerencia"
                        label="Gerencia"
                        clearable
                        clear-icon="mdi-close"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-text-field dense v-model="area" label="Área" clearable clear-icon="mdi-close"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" class="py-0">
                      <div>
                        <v-data-table
                          dense
                          :headers="cObjetivo"
                          :items="objetivo"
                          :items-per-page="-1"
                          class="border"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <div
                              class="primary w-100 d-flex justify-space-between px-2 white--text body-1"
                              style="height: 40px;"
                            >
                              <div class="d-flex align-center py-1">
                                <u>O</u>BJETIVOS
                                <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                              </div>
                              <div class="d-flex align-center">
                                <v-btn
                                  color="white"
                                  fab
                                  icon
                                  small
                                  @click="addRowsObjetivo"
                                  v-shortkey="['ctrl', 'o']"
                                  @shortkey="addRowsObjetivo"
                                >
                                  <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </template>
                          <template v-slot:[`item.descripcion`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.descripcion">
                              {{ item.descripcion }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="item.descripcion"
                                  @blur="item.descripcion = item.descripcion.toUpperCase()"
                                  label="Descripción"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:[`item.acciones`]="{ item }">
                            <v-icon small @click="deleteItemObjetivo(item.id)">mdi-delete</v-icon>
                          </template>
                          <template v-slot:no-data>
                            <span>No se encontró información</span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="py-0 mb-4">
                      <div>
                        <v-data-table
                          dense
                          :headers="cJustificacion"
                          :items="justificacion"
                          :items-per-page="-1"
                          class="border"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <div
                              class="primary w-100 d-flex justify-space-between px-2 white--text body-1"
                              style="height: 40px;"
                            >
                              <div class="d-flex align-center py-1">
                                <u>J</u>USTIFICACIÓN
                                <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                              </div>
                              <div class="d-flex align-center">
                                <v-btn
                                  color="white"
                                  fab
                                  icon
                                  small
                                  class="mb-2"
                                  @click="addRowsJustificacion"
                                  v-shortkey="['ctrl', 'j']"
                                  @shortkey="addRowsJustificacion"
                                >
                                  <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </template>
                          <template v-slot:[`item.descripcion`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.descripcion">
                              {{ item.descripcion }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="item.descripcion"
                                  @blur="item.descripcion = item.descripcion.toUpperCase()"
                                  label="Descripción"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:[`item.acciones`]="{ item }">
                            <v-icon small @click="deleteItemPlan(item.id)">mdi-delete</v-icon>
                          </template>
                          <template v-slot:no-data>
                            <span>No se encontró información</span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                      <div>
                        <v-data-table
                          dense
                          :headers="cEquipo"
                          :items="equipos"
                          :items-per-page="-1"
                          class="border"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <div
                              class="primary w-100 d-flex justify-space-between px-2 white--text body-1"
                              style="height: 40px;"
                            >
                              <div class="d-flex align-center py-1">
                                <u>E</u>QUIPO DEL PROYECTO
                                <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                              </div>
                              <div class="d-flex align-center">
                                <v-btn
                                  color="white"
                                  fab
                                  icon
                                  small
                                  @click="addRowsEquipo"
                                  v-shortkey="['ctrl', 'e']"
                                  @shortkey="addRowsEquipo"
                                >
                                  <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </template>
                          <template v-slot:[`item.dni`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.dni">
                              {{ item.dni }}
                              <template v-slot:input>
                                <v-text-field v-model="item.dni" label="Dni" single-line type="number"></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:[`item.name`]="{ item }">
                            <v-edit-dialog
                              :return-value.sync="item.object"
                              @save="saveName(item.id, item.object, item.new)"
                            >
                              {{ item.name }}
                              <template v-slot:input>
                                <div v-if="!item.new" class="d-flex align-baseline">
                                  <v-autocomplete
                                    v-model="item.object"
                                    :search-input.sync="search"
                                    :items="itemsParticipantes"
                                    item-text="name"
                                    item-value="code"
                                    label="Nombres"
                                    placeholder="Nombres"
                                    prepend-icon="mdi-database-search"
                                    hide-no-data
                                    hide-selected
                                    return-object
                                  >
                                    <template v-slot:selection="data">
                                      {{ data.item.name }}
                                    </template>
                                    <template v-slot:item="data">
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                          <v-list-item-subtitle>
                                            {{
                                              `${data.item.dni} - ${
                                                data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'
                                              }`
                                            }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </template>
                                  </v-autocomplete>
                                  <v-btn icon color="primary" @click="changeNewEditText(item.id, 'I')">
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </div>
                                <v-text-field
                                  v-else
                                  v-model="item.name"
                                  @blur="item.name = item.name.toUpperCase()"
                                  label="Nombres"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:[`item.email`]="{ item }">
                            <v-edit-dialog @save="saveEmail(item.id, 'I')">
                              {{ item.email }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="item.email"
                                  label="Email"
                                  :suffix="item.email.indexOf('@') !== -1 ? '' : '@agrovisioncorp.com'"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:[`item.tipo`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.tipo">
                              <v-chip small :color="`${item.tipo.color} darken-2`" dark>{{ item.tipo.text }}</v-chip>
                              <template v-slot:input>
                                <v-select
                                  v-model="item.tipo"
                                  :items="tiposResponsables"
                                  no-data-text="No se encontró información"
                                  item-text="text"
                                  item-value="value"
                                  label="Tipo"
                                  return-object
                                  single-line
                                ></v-select>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:[`item.acciones`]="{ item }">
                            <v-icon small @click="deleteItem(item.id, 'I')">mdi-delete</v-icon>
                          </template>
                          <template v-slot:no-data>
                            <span>No se encontró información</span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mb-4 d-flex justify-end pr-4">
                    <v-btn small color="primary" @click="stepper = 2">
                      Siguiente
                    </v-btn>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-row>
                    <v-col cols="12" sm="12" class="d-flex justify-end">
                      <v-btn class="mx-2" fab dark small color="primary" @click="addEtapa">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <table-gantt-elastic
                    :options="options"
                    :tasks="tasks"
                    :view-tasks="true"
                    :view-chart="false"
                    @tasks-changed="tasksUpdate"
                    @options-changed="optionsUpdate"
                    @dynamic-style-changed="styleUpdate"
                  >
                  </table-gantt-elastic>
                  <div class="text-center">
                    <v-dialog v-model="dialogGantt" persistent max-width="600px">
                      <v-card>
                        <v-card-title class="py-3 mt-2">
                          <span class="headline"> {{ messageDialog }}</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pt-3">
                          <v-row class="mt-4">
                            <v-col cols="12" sm="12" md="12" class="py-0">
                              <v-text-field dense label="Nombre*" v-model="taskName" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="isTask" class="py-0">
                              <v-text-field dense label="Entregable*" v-model="entregable" required></v-text-field>
                            </v-col>
                            <v-col v-if="isTask" cols="12" sm="12" md="12" class="py-0">
                              <v-autocomplete
                                dense
                                v-model="taskAssignedTo"
                                :search-input.sync="search"
                                :items="itemsAssignedTo"
                                item-text="name"
                                item-value="code"
                                label="Asignado a*"
                                placeholder="Asignado a"
                                prepend-icon="mdi-database-search"
                                hide-no-data
                                hide-selected
                                return-object
                                multiple
                                @input="search = null"
                                chips
                              >
                                <template v-slot:selection="data">
                                  <v-chip close @click:close="removeAssigned(data.item.dni)">
                                    {{ data.item.name }}
                                  </v-chip>
                                </template>
                                <template v-slot:item="data">
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          `${data.item.dni} - ${
                                            data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'
                                          }`
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" v-if="isTask" class="py-0 mb-4">
                              <v-data-table
                                dense
                                :headers="cabeceras"
                                :items="taskSupportTo"
                                :items-per-page="-1"
                                class="border"
                                hide-default-footer
                                :mobile-breakpoint="0"
                                no-data-text="No se encontraron datos"
                                no-results-text="No se encontraron datos"
                              >
                                <template v-slot:top>
                                  <div
                                    class="primary w-100 d-flex justify-space-between px-2 white--text body-1"
                                    style="height: 40px;"
                                  >
                                    <div class="d-flex align-center py-1">
                                      <u>A</u>POYO
                                      <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                                    </div>
                                    <div class="d-flex align-center">
                                      <v-btn
                                        color="white"
                                        fab
                                        icon
                                        small
                                        @click="addRows()"
                                        v-shortkey="['ctrl', 'a']"
                                        @shortkey="addRows()"
                                      >
                                        <v-icon dark>mdi-plus</v-icon>
                                      </v-btn>
                                    </div>
                                  </div>
                                </template>
                                <template v-slot:[`item.dni`]="{ item }">
                                  <v-edit-dialog :return-value.sync="item.dni">
                                    {{ item.dni }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="item.dni"
                                        label="Dni"
                                        single-line
                                        type="number"
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>
                                <template v-slot:[`item.name`]="{ item }">
                                  <v-edit-dialog
                                    :return-value.sync="item.object"
                                    @save="saveNameApoyo(item.id, item.object, item.new)"
                                  >
                                    {{ item.name }}
                                    <template v-slot:input>
                                      <div v-if="!item.new" class="d-flex align-baseline">
                                        <v-autocomplete
                                          v-model="item.object"
                                          :search-input.sync="search"
                                          :items="itemsSpportTo"
                                          item-text="name"
                                          item-value="code"
                                          label="Nombres"
                                          placeholder="Nombres"
                                          prepend-icon="mdi-database-search"
                                          hide-no-data
                                          hide-selected
                                          return-object
                                        >
                                          <template v-slot:selection="data">
                                            {{ data.item.name }}
                                          </template>
                                          <template v-slot:item="data">
                                            <template>
                                              <v-list-item-content>
                                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                <v-list-item-subtitle>
                                                  {{
                                                    `${data.item.dni} - ${
                                                      data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'
                                                    }`
                                                  }}
                                                </v-list-item-subtitle>
                                              </v-list-item-content>
                                            </template>
                                          </template>
                                        </v-autocomplete>
                                        <v-btn icon color="primary" @click="changeNewEditText(item.id, 'A')">
                                          <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                      </div>
                                      <v-text-field
                                        v-else
                                        v-model="item.name"
                                        label="Nombres"
                                        single-line
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>
                                <template v-slot:[`item.email`]="{ item }">
                                  <v-edit-dialog @save="saveEmail(item.id, 'A')">
                                    {{ item.email }}
                                    <template v-slot:input>
                                      <v-text-field
                                        v-model="item.email"
                                        label="Email"
                                        :suffix="item.email.indexOf('@') !== -1 ? '' : '@agrovisioncorp.com'"
                                        single-line
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                  <v-icon small @click="deleteItem(item.id, 'A')">mdi-delete</v-icon>
                                </template>
                                <template v-slot:no-data>
                                  <span>No se encontró información</span>
                                </template>
                              </v-data-table>
                            </v-col>

                            <v-col v-if="isTask" cols="12" sm="6" md="6" class="py-0">
                              <v-menu
                                v-model="menuDateStart"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    dense
                                    v-model="taskDateStart"
                                    prepend-icon="mdi-calendar"
                                    label="Fecha inicio"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="taskDateStart"
                                  @input="menuDateStart = false"
                                  locale="es-es"
                                  show-week
                                  first-day-of-week="1"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>

                            <v-col v-if="isTask" cols="12" sm="6" md="6" class="py-0">
                              <v-menu
                                v-model="menuDateEnd"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    dense
                                    v-model="taskDateEnd"
                                    prepend-icon="mdi-calendar"
                                    label="Fecha fin"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="taskDateEnd"
                                  @input="menuDateEnd = false"
                                  locale="es-es"
                                  :first-day-of-week="1"
                                  show-week
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col v-if="isTask" cols="12" sm="6" md="6" class="py-0">
                              <v-text-field
                                dense
                                ref="refTaskBudget"
                                label="Presupuesto"
                                v-model.lazy="taskBudget"
                                v-money="money"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <small class="red--text darken-2">*Campos requeridos</small>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="closeAddTask">Cerrar</v-btn>
                          <v-btn v-if="!isEditDialog" color="primary" text @click="aceptAddTask">Guardar</v-btn>
                          <v-btn v-else color="warning" text @click="aceptEditTask">Aceptar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                  <vue-context ref="menu">
                    <li :key="i" v-for="(el, i) in contextMenuItems">
                      <div
                        v-if="el.enable"
                        class="flex align-center my-3 mr-4"
                        style="cursor: pointer"
                        @click.prevent="onClickContextMenu(el.id)"
                      >
                        <v-icon small class="mr-2">{{ el.icon }}</v-icon>
                        <span>{{ el.text }} {{ el.context }}</span>
                      </div>
                    </li>
                  </vue-context>
                  <v-row class="mb-4 d-flex justify-start mt-4 pl-4">
                    <v-btn small color="primary" @click="stepper = 1">
                      Anterior
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="error" @click="closeProyectoEdit()">Cerrar</v-btn>
            <v-btn small v-auth-acl="'gh-proy-cre_proy-insert'" color="primary" submit @click="actualizar()"
              >Actualizar</v-btn
            >
          </v-card-actions>
          <div v-if="showLoading" class="progress-background">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import TableGanttElastic from '@/components4x/table/TableGanttElastic';
// import Header from '@/components4x/table/Header';
import VueContext from 'vue-context';
import dayjs from 'dayjs';
import 'vue-context/src/sass/vue-context.scss';
import Swal from 'sweetalert2';
import { VMoney } from 'v-money';
import MatrizProyectoService from '../services/MatrizProyectoService';
import { decryptAES } from '@/components4x/utils/utils4x';

function convertDate(date) {
  const hours = 0; // mañana 24 | hoy 0
  const stringDate = date.split('-');
  const currentYear = parseInt(stringDate[0]);
  const currentMonth = parseInt(stringDate[1]) - 1;
  const currentDay = parseInt(stringDate[2]);
  const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
  return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
}
export default {
  name: 'ProyectoFormulario',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    },
    object: {
      type: Object,
      default: () => ({
        objetivosjson: [],
        justificacionjson: [],
        equiposjson: [],
        gerencia: '',
        titulo: '',
        area: '',
        presupuesto: '',
        fechainicio: '',
        fechafin: '',
        estadoproyecto: null
      })
    },
    objtable: {
      type: Object,
      default: () => ({
        name: '',
        start: convertDate(moment().format('YYYY-MM-DD')),
        end: convertDate(
          moment()
            .add(1, 'days')
            .format('YYYY-MM-DD')
        ),
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        percent: 0,
        budget: 0,
        typex: 'proyecto'
      })
    }
  },
  components: {
    // ganttElasticHeader: Header,
    tableGanttElastic: TableGanttElastic,
    VueContext
  },
  data: () => ({
    proyectoService: null,
    showLoading: false,
    stepper: 1,
    area: '',
    proyectoTitulo: '',
    gerencia: '',
    max50chars: (v) => v.length <= 50 || 'El campo excedio el límite!',
    required: (v) => v.length !== 0 || 'El campo no debe estar vacío',
    max100chars: (v) => v.length <= 100 || 'El campo excedio el límite!',
    max8chars: (v) => v.length === 8 || 'El campo tiene que contener 8 dígitos!',
    money: {
      decimal: '.',
      thousands: ',',
      prefix: '$',
      suffix: '',
      precision: 2
    },
    cObjetivo: [
      { text: 'N°', align: 'start', value: 'index', width: '50' },
      { text: 'Descripcion', value: 'descripcion', width: '200' },
      { text: 'Acciones', value: 'acciones', width: '50' }
    ],
    cJustificacion: [
      { text: 'N°', align: 'start', value: 'index', width: '50' },
      { text: 'Descripción', value: 'descripcion', width: '200' },
      { text: 'Acciones', value: 'acciones', width: '50' }
    ],
    cEquipo: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Dni',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Tipo', value: 'tipo' },
      { text: 'Acciones', value: 'acciones' }
    ],
    tiposResponsables: [
      { value: 'SP', color: 'green', text: 'SPONSOR' },
      { value: 'LI', color: 'orange', text: 'LIDER' },
      { value: 'ET', color: 'red', text: 'EQUIPO TÉCNICO' },
      { value: 'PA', color: 'yellow', text: 'PARTICIPANTES' }
    ],
    equipos: [],
    dates: [],
    objetivo: [],
    justificacion: [],
    search: '',

    // TABLEEEEEEEEEEEEEEEE
    tasks: [],
    options: [],
    dynamicStyle: {},
    lastId: 0,
    parent: 0,
    dialogGantt: false,
    isEditDialog: false,
    messageDialog: '',
    menuDate: false,
    menuDateStart: false,
    menuDateEnd: false,
    isTask: false,
    taskName: '',
    entregable: '',
    taskAssignedTo: [],
    taskSupportTo: [],
    taskDateStart: moment().format('YYYY-MM-DD'),
    taskDateEnd: moment().format('YYYY-MM-DD'),
    taskStart: new Date().toISOString().substr(0, 10),
    taskEnd: '',
    taskType: 'tarea',
    taskDuration: '1',
    taskDurationContext: { id: 'h', text: 'horas' },
    taskPercentage: '0',
    taskBudget: 0,
    taskIdTypeTask: '',
    contextMenuItems: [
      { id: 1, text: 'Añadir Nueva', context: 'tarea', icon: 'mdi-plus', enable: true },
      { id: 2, text: 'Editar', context: 'tarea', icon: 'mdi-pencil', enable: true },
      // { id: 3, text: 'Subir', context: 'tarea', icon: 'mdi-arrow-up', enable: false },
      // { id: 4, text: 'Bajar', context: 'tarea', icon: 'mdi-arrow-down', enable: false },
      { id: 5, text: 'Eliminar', context: 'tarea', icon: 'mdi-delete', enable: true }
    ],
    taskListDurationContext: [
      { id: 'h', text: 'horas' },
      { id: 'd', text: 'dias' },
      { id: 's', text: 'semanas' },
      { id: 'm', text: 'mes' }
    ],
    dataRowList: {},
    levelTask: false,
    tiempoEstimado: '27/11/2019',
    itemsParticipantes: [],
    itemsAssignedTo: [],
    itemsSpportTo: [],
    estadoproyecto: null,
    cabeceras: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Dni',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Acciones', value: 'actions' }
    ]
  }),
  directives: { money: VMoney },
  computed: {},
  watch: {
    async object(val) {
      await this.initialize();
      await this.cargarDatos(val);
    }
  },
  methods: {
    closeProyectoEdit() {
      this.$emit('closeProyectoEdit');
    },
    addRows() {
      this.taskSupportTo.push({
        id: uuidv4(),
        dni: '',
        name: '',
        email: '',
        new: false,
        object: {}
      });
    },
    saveNameApoyo(id, object, inew) {
      if (inew) return;

      if (object === undefined) {
        this.clearRow(id);
        return;
      }

      const dni = object.dni;

      if (dni === '' || dni === undefined) {
        this.clearRow(id);
        return;
      }
      const res = this.itemsSpportTo.filter((el) => el.dni === dni);
      this.taskSupportTo.map((el) => {
        if (el.id === id) {
          el.dni = res[0].dni;
          el.name = res[0].name;
          el.email = res[0].email;
        }
        return el;
      });
    },
    clearRow(id) {
      const i = this.taskSupportTo.findIndex((el) => el.id === id);
      this.taskSupportTo.splice(i, 1);
    },
    async initialize() {
      this.showLoading = true;
      const res = await this.proyectoService.get().execResource('responsables', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });

      if (res.length > 0) {
        res.forEach((el) => {
          this.itemsParticipantes.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            name: el.nombresall,
            email: el.email,
            idsubarea: el.idsubarea,
            area: el.area, // En realidad es la subarea
            status: 'N',
            leader: false,
            new: false,
            object: {}
          });
          if (el.idsubarea != '') {
            this.itemsAssignedTo.push({
              id: uuidv4(),
              code: el.idresponsable,
              dni: el.idtrabajador,
              name: el.nombresall,
              email: el.email,
              idsubarea: el.idsubarea,
              area: el.area, // En realidad es la subarea
              status: 'N',
              leader: false,
              new: false,
              object: {}
            });
          }
          this.itemsSpportTo.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            name: el.nombresall,
            email: el.email,
            idsubarea: el.idsubarea,
            area: el.area, // En realidad es la subarea
            status: 'N',
            leader: false,
            new: false,
            object: {}
          });
        });
      } else {
        this.itemsParticipantes = [];
        this.itemsAssignedTo = [];
        this.supportTo = [];
      }

      this.showLoading = false;
    },
    clearRowInvitados(id) {
      const i = this.equipos.findIndex((el) => el.id === id);
      this.equipos.splice(i, 1);
    },
    saveName(id, object, inew) {
      if (inew) return;

      if (object === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const dni = object.dni;

      if (dni === '' || dni === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const res = this.itemsParticipantes.filter((el) => el.dni === dni);
      this.equipos.map((el) => {
        if (el.id === id) {
          el.dni = res[0].dni;
          el.code = res[0].code;
          el.name = res[0].name;
          el.email = res[0].email;
          el.idsubarea = res[0].idsubarea;
          el.area = res[0].area;
          el.status = 'N';
        }
        return el;
      });
    },
    saveEmail(id, tipo) {
      if (tipo == 'A') {
        this.taskSupportTo.map(
          (el) =>
            (el.email =
              el.id === id ? (el.email.indexOf('@') !== -1 ? el.email : `${el.email}@agrovisioncorp.com`) : el.email)
        );
      } else {
        this.equipos.map(
          (el) =>
            (el.email =
              el.id === id ? (el.email.indexOf('@') !== -1 ? el.email : `${el.email}@agrovisioncorp.com`) : el.email)
        );
      }
    },
    changeNewEditText(id, tipo) {
      if (tipo == 'A') {
        this.taskSupportTo.map((el) => (el.new = el.id === id ? true : el.new));
      } else {
        this.equipos.map((el) => (el.new = el.id === id ? true : el.new));
      }
    },
    addRowsObjetivo() {
      const index = this.objetivo.length <= 0 ? 1 : this.objetivo.length + 1;
      this.objetivo.push({ id: uuidv4(), index: index.toString().padStart(3, '0'), descripcion: '' });
    },
    addRowsJustificacion() {
      const index = this.justificacion.length <= 0 ? 1 : this.justificacion.length + 1;
      this.justificacion.push({ id: uuidv4(), index: index.toString().padStart(3, '0'), descripcion: '' });
    },
    addRowsEquipo() {
      this.equipos.push({
        id: uuidv4(),
        dni: '',
        code: '',
        name: '',
        email: '',
        leader: false,
        area: '',
        idsubarea: '',
        status: 'N',
        tipo: { value: 'PA', color: 'yellow', text: 'PARTICIPANTES' },
        new: false,
        object: {}
      });
    },
    addEtapa() {
      this.isTask = false;
      this.taskIdTypeTask = 'E';
      this.messageDialog = 'Nueva Etapa';
      this.isEditDialog = false;
      this.dialogGantt = true;
    },
    async actualizar() {
      let arrSponsor = [];
      let arrLider = [];
      let arrEquipoTecnico = [];
      let arrParticipantes = [];

      if (this.proyectoTitulo == '') {
        await this.alertDialog('warning', 'Ingrese un título');
        return;
      }
      if (this.gerencia == '') {
        await this.alertDialog('warning', 'Ingrese gerencia');
        return;
      }
      if (this.area == '') {
        await this.alertDialog('warning', 'Ingrese área');
        return;
      }
      if (this.objetivo.length <= 0) {
        await this.alertDialog('warning', 'Ingrese objetivos');
        return;
      }
      if (this.justificacion.length <= 0) {
        await this.alertDialog('warning', 'Ingrese justificación');
        return;
      }
      if (this.equipos.length <= 0) {
        await this.alertDialog('warning', 'Ingrese equipo');
        return;
      }
      let auxValidEquipo = false;
      this.equipos.forEach((el) => {
        if (el.dni == '' || el.nombre == '') {
          auxValidEquipo = true;
        }
      });
      if (auxValidEquipo) {
        this.alertDialog('warning', 'Falta dni/nombres de algunos participantes del equipo del proyecto');
        return;
      }
      this.equipos.forEach((el) => {
        switch (el.tipo.value) {
          case 'SP':
            arrSponsor.push({ ...el });
            break;
          case 'LI':
            arrLider.push({ ...el });
            break;
          case 'ET':
            arrEquipoTecnico.push({ ...el });
            break;
          case 'PA':
            arrParticipantes.push({ ...el });
            break;
        }
      });
      if (arrLider.length <= 0) {
        this.alertDialog('warning', 'Falta elegir lider en los participantes del equipo del proyecto');
        return;
      }
      let idliderstr = [];
      let idsponsorstr = [];
      let idequipotecnicostr = [];
      let idparticipantesstr = [];

      arrLider.forEach((el) => idliderstr.push(el.code));
      arrSponsor.forEach((el) => idsponsorstr.push(el.code));
      arrEquipoTecnico.forEach((el) => idequipotecnicostr.push(el.code));
      arrParticipantes.forEach((el) => idparticipantesstr.push(el.code));

      let arrEquipo = [];

      this.equipos.forEach((el) => {
        arrEquipo.push({
          area: el.area,
          code: el.code,
          dni: el.dni,
          email: el.email,
          id: el.id,
          idsubarea: el.idsubarea,
          name: el.name,
          new: false,
          tipo: el.tipo.value
        });
      });

      let arrTasks = [];

      this.tasks.forEach((el) => {
        arrTasks.push({
          id: el.id,
          label: el.label,
          entregable: el.entregable,
          usuario: el.usuario,
          idresponsible: el.idresponsible,
          support: el.support,
          idsupport: el.idsupport,
          dateStart: el.dateStart,
          dateEnd: el.dateEnd,
          budget: el.budget,
          type: el.type,
          idtype: el.idtype,
          parents: el.parents !== null ? el.parents.join(',') : null,
          level: el.level
        });
      });

      //-------------------------------------------------------------------
      this.showLoading = true;
      const res = await this.proyectoService.put().execResource('proyecto', {
        idempresa: decryptAES(localStorage.getItem('emp')),
        titulo: this.proyectoTitulo,
        gerencia: this.gerencia,
        area: this.area,
        idlider: idliderstr.join(','),
        idsponsor: idsponsorstr.join(','),
        idequipotecnico: idequipotecnicostr.join(','),
        idparticipantes: idparticipantesstr.join(','),
        objetivos: this.objetivo,
        justificacion: this.justificacion,
        equipos: arrEquipo,
        etapas: arrTasks,
        estado: this.estadoproyecto,
        idproyecto: this.id
      });

      this.showLoading = false;

      if (res.status) {
        await this.alertDialog('success', res.data);
        await this.closeProyectoEdit();
        await this.$parent.initialize();
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    save() {
      console.log('Data saved');
    },
    cancel() {
      console.log('Canceled');
    },
    open() {
      console.log('Dialog opened');
    },
    deleteItemObjetivo(id) {
      const i = this.objetivo.findIndex((el) => el.id === id);
      this.objetivo.splice(i, 1);
      this.objetivo.map((el, index) => (el.index = (index + 1).toString().padStart(3, '0')));
    },
    deleteItemPlan(id) {
      const i = this.justificacion.findIndex((el) => el.id === id);
      this.justificacion.splice(i, 1);
      this.justificacion.map((el, index) => (el.index = (index + 1).toString().padStart(3, '0')));
    },
    deleteItem(id, tipo) {
      if (tipo == 'A') {
        const i = this.taskSupportTo.findIndex((el) => el.id === id);
        this.taskSupportTo.splice(i, 1);
      } else {
        const i = this.equipos.findIndex((el) => el.id === id);
        this.equipos.splice(i, 1);
      }
    },
    close() {
      console.log('Dialog closed');
    },

    // TABLEEEEEEEEEEEEEEEE
    tasksUpdate(tasks) {
      this.tasks = tasks;
    },
    optionsUpdate(options) {
      this.options = options;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    },
    onClickContextMenu(id) {
      console.log(id);
      switch (id) {
        case 1:
          this.addTask();
          break;
        case 2:
          this.editTask(this.dataRowList);
          break;
        case 3:
          this.addAbove(this.dataRowList);
          break;
        case 4:
          this.addBelow(this.dataRowList);
          break;
        case 5:
          this.deleteTask(this.dataRowList);
          break;
      }
    },
    addTask() {
      this.isEditDialog = false;
      this.dialogGantt = true;
    },
    addAbove() {},
    addBelow() {},
    async deleteTask(data) {
      const { isConfirmed } = await Swal.fire({
        icon: 'warning',
        title: `¿Desea eliminar la tarea?`,
        confirmButtonText: `Aceptar`,
        cancelButtonText: `Cancelar`
      });

      if (isConfirmed) {
        let i = 0;
        for (const task of this.tasks) {
          console.log('task', task);
          console.log('data', data);
          if (task.id === data.id) break;
          i++;
        }
        console.log('index', i);
        this.tasks.splice(i, 1);
      }
    },
    closeAddTask() {
      this.dialogGantt = false;
      this.isEditDialog = false;
      this.clear();
    },
    maxIdTask() {
      const arrIds = [];
      if (this.tasks.length <= 0) return 1;
      this.tasks.forEach((el) => arrIds.push(parseInt(el.id)));
      console.log('arrIds', arrIds);
      console.log('lastId', this.lastId);
      return Math.max(...arrIds) + 1;
    },
    async aceptAddTask() {
      console.log('clear', this.dataRowList);

      if (this.taskName == '' || this.taskName.trim() == '') {
        await this.alertDialog('warning', 'Ingrese Titulo de tarea');
        return;
      }
      if (this.taskIdTypeTask === 'E') this.dataRowList = {};

      let liResponsible = '';
      let ulResponsibles = '';

      this.taskAssignedTo.forEach((el) => (liResponsible += `<li>${el.name}</li>`));
      ulResponsibles = `<ul>${liResponsible}</ul>`;

      let liSupport = '';
      let ulSupport = '';

      this.taskSupportTo.forEach((el) => (liSupport += `<li>${el.name}</li>`));
      ulSupport = `<ul>${liSupport}</ul>`;
      const maxId = this.maxIdTask();

      this.tasks.push({
        id: maxId,
        label: this.taskName,
        entregable: this.entregable,
        usuario: ulResponsibles,
        idresponsible: this.taskAssignedTo,
        support: ulSupport,
        idsupport: this.taskSupportTo,
        start: this.convertDate(moment().format('YYYY-MM-DD')),
        dateStart: this.taskDateStart,
        dateEnd: this.taskDateEnd,
        parentId: Object.prototype.hasOwnProperty.call(this.dataRowList, 'id') ? parseInt(this.dataRowList.id) : null,
        duration: this.convertDuration(1, 'h'),
        durationContext: 'h',
        percent: 0,
        budget: parseFloat(this.taskBudget.toString().replace(/[$,]/g, '')),
        type: this.convertTypeTask(this.taskIdTypeTask),
        idtype: this.taskIdTypeTask,
        level: this.convertLevelTask(this.taskIdTypeTask)
      });

      this.dataRowList = {};
      this.closeAddTask();

      await this.timeout(1000);
      const rep = this.tasks.filter((el) => el.id === maxId);

      console.log(rep[0]);
      console.log('task', maxId, this.tasks);

      const parents = [...rep[0].parents];
      this.recalculate(parents);
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    editTask() {
      console.log(this.dataRowList);
      this.isTask = this.dataRowList.idtype === 'T';

      const resDurationContext = this.taskListDurationContext.filter(
        (el) => this.dataRowList.durationContext === el.id
      );

      const resItemsAssignedTo = [];
      const resItemsSupportTo = [];

      if (this.dataRowList.idresponsible.length > 0) {
        this.dataRowList.idresponsible.forEach((el) => {
          const res = this.itemsAssignedTo.filter((e) => e.dni === el.dni);
          if (res.length > 0) {
            resItemsAssignedTo.push({ ...res[0] });
          }
        });
      }

      if (this.dataRowList.idsupport.length > 0) {
        this.dataRowList.idsupport.forEach((el) => {
          const res = this.itemsSpportTo.filter((e) => e.dni === el.dni);
          if (res.length > 0) {
            resItemsSupportTo.push({ ...res[0] });
          }
        });
      }

      console.log('Edit', resItemsAssignedTo, resItemsSupportTo);
      console.log(dayjs(this.dataRowList.start).valueOf());

      this.messageDialog = `Editar ${this.convertTypeTask(this.dataRowList.idtype)}`;
      this.isEditDialog = true;

      this.taskName = this.dataRowList.label;
      this.entregable = this.dataRowList.entregable;

      this.taskAssignedTo = resItemsAssignedTo;
      this.taskSupportTo = resItemsSupportTo;

      this.taskStart = new Date(this.dataRowList.start).toISOString().substr(0, 10);
      this.taskDateStart = this.dataRowList.dateStart;
      this.taskDateEnd = this.dataRowList.dateEnd;
      this.taskEnd = '';
      this.taskType = this.dataRowList.type;
      this.taskDuration = this.convertDuration(this.dataRowList.duration, this.dataRowList.durationContext, true);
      this.taskDurationContext = resDurationContext[0];
      this.taskPercentage = this.dataRowList.percent;
      this.taskIdTypeTask = this.dataRowList.idtype;
      this.type = this.convertTypeTask(this.dataRowList.idtype);
      this.taskBudget = parseFloat(this.dataRowList.budget).toFixed(2);

      this.dialogGantt = true;
    },
    async aceptEditTask() {
      let liResponsible = '';
      let ulResponsibles = '';

      if (this.taskName == '' || this.taskName.trim() == '') {
        await this.alertDialog('warning', 'Ingrese Titulo de tarea');
        return;
      }

      this.taskAssignedTo.forEach((el) => (liResponsible += `<li>${el.name}</li>`));
      ulResponsibles = `<ul>${liResponsible}</ul>`;

      let liSupport = '';
      let ulSupport = '';

      this.taskSupportTo.forEach((el) => (liSupport += `<li>${el.name}</li>`));
      ulSupport = `<ul>${liSupport}</ul>`;

      console.log(ulResponsibles, ulSupport);

      this.tasks.map((el) => {
        if (el.id === this.dataRowList.id) {
          el.label = this.taskName;
          el.entregable = this.entregable;
          el.usuario = ulResponsibles;
          el.support = ulSupport;
          el.idresponsible = this.taskAssignedTo;
          el.idsupport = this.taskSupportTo;
          el.start = this.convertDate(this.taskStart);
          el.dateStart = this.taskDateStart;
          el.dateEnd = this.taskDateEnd;
          el.duration = this.convertDuration(1, 'h');
          el.durationContext = 'h';
          el.percent = 0;
          el.progress = 0;
          el.budget = parseFloat(this.taskBudget.toString().replace(/[$,]/g, ''));
          el.type = this.convertTypeTask(this.taskIdTypeTask);
          el.idtype = this.taskIdTypeTask;
          el.level = this.convertLevelTask(this.taskIdTypeTask);
        }
        return el;
      });

      const parents = [...this.dataRowList.parents];
      this.recalculate(parents);

      this.dataRowList = {};
      this.closeAddTask();
    },
    convertDate(date) {
      const hours = 0; // mañana 24 | hoy 0
      const stringDate = date.split('-');
      const currentYear = parseInt(stringDate[0]);
      const currentMonth = parseInt(stringDate[1]) - 1;
      const currentDay = parseInt(stringDate[2]);
      const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
      return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
    },
    recalculate(parents) {
      const parentsReverse = parents.reverse();

      console.log('parentsReverse', parentsReverse);

      for (const parent of parentsReverse) {
        let taskChildrens = null;
        let pushChildren = [];
        let pushBudget = [];
        this.tasks.map((el) => {
          if (el.id === parent) {
            if (el.children.length > 0) {
              taskChildrens = [...el.children];
              for (const childrens of taskChildrens) {
                this.tasks.forEach((elem) => {
                  if (elem.id === childrens) {
                    pushChildren.push(elem.dateStart);
                    pushChildren.push(elem.dateEnd);
                    pushBudget.push(elem.budget);
                  }
                });
              }
              const resDates = this.calculateMinMaxDates(pushChildren);
              const resBudget = this.calculateBudget(pushBudget);
              el.dateStart = resDates.min;
              el.dateEnd = resDates.max;
              el.budget = resBudget;
            }
          }
          return el;
        });
      }
    },
    clear() {
      this.taskName = '';
      this.entregable = '';
      this.taskAssignedTo = [];
      this.taskSupportTo = [];
      this.taskStart = new Date().toISOString().substr(0, 10);
      this.taskDateStart = moment().format('YYYY-MM-DD');
      this.taskDateEnd = moment().format('YYYY-MM-DD');
      this.taskEnd = '';
      this.taskType = '';
      this.taskDuration = 1;
      this.taskBudget = 0;
      this.taskDurationContext = { id: 'h', text: 'horas' };
      this.taskPercentage = 0;
      this.taskIdTypeTask = '';
      this.isTask = false;
    },
    convertDuration(taskDuration, taskType, reverse = false) {
      console.log(taskDuration, taskType);
      const duration = parseFloat(taskDuration);
      const type = taskType.toLowerCase();
      const base = 60 * 60 * 1000;
      const day = 24;
      const sem = 7;
      const month = 30;
      // const daysOfMonth = new Date(2020,12,0).getDate();

      switch (type) {
        case 'h':
          return reverse ? duration / 1000 / 60 / 60 : duration * base;
        case 'd':
          return reverse ? duration / 1000 / 60 / 60 / day : duration * day * base;
        case 's':
          return reverse ? duration / 1000 / 60 / 60 / day / sem : duration * sem * day * base;
        case 'm':
          return reverse ? duration / 1000 / 60 / 60 / day / sem / month : duration * month * sem * day * base;
      }
    },
    calculateMinMaxDates(periodos) {
      const dates = [];
      periodos.forEach((el) => dates.push(moment(el)));
      return {
        min: moment(new Date(Math.min.apply(null, dates))).format('YYYY-MM-DD'),
        max: moment(new Date(Math.max.apply(null, dates))).format('YYYY-MM-DD')
      };
    },
    calculatePercent(percentages) {
      return percentages.reduce((sum, el) => sum + el, 0);
    },
    calculateBudget(budget) {
      return budget.reduce(
        (sum, el) => parseFloat(parseFloat(sum).toFixed(2)) + parseFloat(parseFloat(el).toFixed(2)),
        0
      );
    },
    removeAssigned(dni) {
      const index = this.taskAssignedTo.findIndex((el) => el.dni === dni);
      if (index >= 0) this.taskAssignedTo.splice(index, 1);
    },
    removeSupport(dni) {
      const index = this.taskSupportTo.findIndex((el) => el.dni === dni);
      if (index >= 0) this.taskSupportTo.splice(index, 1);
    },
    convertTypeTask(type) {
      switch (type) {
        case 'E':
          return 'Etapa';
        case 'A':
          return 'Actividad';
        case 'T':
          return 'Tarea';
      }
    },
    convertLevelTask(type) {
      switch (type) {
        case 'E':
          return 1;
        case 'A':
          return 2;
        case 'T':
          return 3;
      }
    },
    //------------------------------------------------------------------------------------------------------------------
    async cargarDatos(object) {
      this.proyectoTitulo = object.titulo;
      this.area = object.area;
      this.gerencia = object.gerencia;
      this.objetivo = object.objetivosjson;
      this.justificacion = object.justificacionjson;
      this.estadoproyecto = object.estadoproyecto;
      object.equiposjson.forEach((el) => {
        this.itemsParticipantes.forEach((e) => {
          if (el.code == e.code) {
            this.equipos.push({
              id: e.id,
              dni: e.dni,
              code: e.code,
              name: e.name,
              email: e.email,
              leader: false,
              area: e.area,
              idsubarea: e.idsubarea,
              status: 'N',
              tipo:
                el.tipo == 'PA'
                  ? { value: 'PA', color: 'yellow', text: 'PARTICIPANTES' }
                  : el.tipo == 'SP'
                  ? { value: 'SP', color: 'green', text: 'SPONSOR' }
                  : el.tipo == 'LI'
                  ? { value: 'LI', color: 'orange', text: 'LIDER' }
                  : { value: 'ET', color: 'red', text: 'EQUIPO TÉCNICO' },
              new: false,
              object: e
            });
          }
        });
      });
    }
  },
  async mounted() {
    this.dates = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
  },
  async created() {
    this.proyectoService = this.$httpService(new MatrizProyectoService(), this);
    this.options = {
      taskMapping: {
        progress: 'percent'
      },
      maxRows: 100,
      maxHeight: 500,
      title: {
        label: 'Your etapa title as html (link or whatever...)',
        html: false
      },
      row: {
        height: 24
      },
      calendar: {
        hour: {
          display: true
        }
      },
      chart: {
        progress: {
          bar: false
        },
        expander: {
          display: false
        }
      },
      taskList: {
        percent: 100,
        expander: {
          straight: true
        },
        columns: [
          {
            id: 1,
            label: 'Nombre de tarea',
            value: 'label',
            width: 250,
            expander: true,
            html: true,
            style: {
              'task-list-header-label': {
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold'
              }
            },
            events: {
              click($event) {
                console.log($event);
              },
              contextmenu: ({ event, data, column }) => {
                switch (data.parents.length) {
                  case 0:
                    this.isTask = false;
                    this.taskIdTypeTask = 'A';
                    this.messageDialog = 'Nueva Actividad';
                    this.contextMenuItems.map((el) => {
                      if (el.id === 1) el.context = 'Actividad';
                      else el.context = 'Etapa';
                      return el;
                    });
                    break;
                  case 1:
                    this.isTask = true;
                    this.taskIdTypeTask = 'T';
                    this.messageDialog = 'Nueva Tarea';
                    this.contextMenuItems.map((el) => {
                      if (el.id === 1) el.context = 'Tarea';
                      else el.context = 'Actividad';
                      return el;
                    });
                    break;
                  case 2:
                    this.isTask = false;
                    this.taskIdTypeTask = '';
                    this.contextMenuItems.map((el) => {
                      el.context = 'Tarea';
                      return el;
                    });
                    break;
                }

                console.log(event, data, column, this.taskIdTypeTask);

                if (data.parents.length >= 2) {
                  this.contextMenuItems.map((el) => (el.enable = el.id === 1 ? false : el.enable));
                } else {
                  this.contextMenuItems.map((el) => (el.enable = true));
                }

                this.dataRowList = Object.assign({}, data);
                this.$refs.menu.open(event);
              }
            }
          },
          {
            id: 2,
            label: 'Asignado a',
            value: 'usuario',
            width: 150,
            html: true,
            style: {
              'task-list-header-label': {
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold'
              }
            }
          },
          {
            id: 3,
            label: 'Apoyo',
            value: 'support',
            width: 150,
            html: true,
            style: {
              'task-list-header-label': {
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold'
              }
            }
          },
          {
            id: 4,
            label: 'Entregable',
            value: 'entregable',
            width: 90,
            style: {
              'task-list-header-label': {
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold'
              }
            }
          },
          {
            id: 5,
            label: 'Presupuesto',
            value: 'budget',
            width: 90,
            style: {
              'task-list-header-label': {
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold'
              }
            }
          },
          {
            id: 6,
            label: 'Tipo',
            value: 'type',
            width: 70,
            style: {
              'task-list-header-label': {
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold'
              }
            }
          },
          {
            id: 7,
            label: 'Fecha inicio',
            value: (task) =>
              task.dateStart
                .split('-')
                .reverse()
                .join('-'),
            width: 80,
            style: {
              'task-list-header-label': {
                'text-align': 'center',
                width: '100%',
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold'
              },
              'task-list-item-value-container': {
                'text-align': 'center',
                width: '100%'
              }
            }
          },
          {
            id: 8,
            label: 'Fecha fin',
            value: (task) =>
              task.dateEnd
                .split('-')
                .reverse()
                .join('-'),
            width: 80,
            style: {
              'task-list-header-label': {
                'text-transform': 'uppercase',
                color: 'white',
                'font-weight': 'bold',
                'text-align': 'center',
                width: '100%'
              },
              'task-list-item-value-container': {
                'text-align': 'center',
                width: '100%'
              }
            }
          }
        ]
      },
      locale: {
        name: 'es',
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sábado'.split('_'),
        weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
        weekStart: 1,
        yearStart: 4,
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
        ordinal: (n) => `${n}º`,
        relativeTime: {
          future: 'en %s',
          past: '%s hace',
          s: 'hace unos segundos',
          m: 'un minuto',
          mm: '%d minutos',
          h: 'una hora',
          hh: '%d horas',
          d: 'un dia',
          dd: '%d dias',
          M: 'un mes',
          MM: '%d meses',
          y: 'un año',
          yy: '%d años'
        }
      }
    };
    this.tasks = [
      {
        id: 0,
        label: this.objtable.name,
        entregable: this.objtable.descripcionentregable,
        usuario: '',
        idresponsible: [],
        support: '',
        idsupport: [],
        dateStart: this.objtable.dateStart,
        dateEnd: this.objtable.dateEnd,
        start: this.objtable.start,
        duration: 0,
        durationContext: 'h',
        percent: 0,
        budget: this.objtable.budget,
        type: this.objtable.typex,
        typex: this.objtable.typex,
        idtype: '',
        level: 1
      }
    ];
  }
};
</script>
