import CryptoJS from 'crypto-js';

function decryptData(data, isJson = false) {
  if (data === null) return null;
  let encrypted = JSON.parse(atob(data.toString()));

  let decrypted = CryptoJS.AES.decrypt(
    encrypted.value,
    CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPT_KEY.toString()),
    {
      iv: CryptoJS.enc.Base64.parse(encrypted.iv)
    }
  );

  let decrypt = decrypted.toString(CryptoJS.enc.Utf8);

  return isJson ? JSON.parse(decrypt) : decrypt;
}

function encryptAES(data, key = process.env.VUE_APP_ENCRYPT_KEY.toString()) {
  if (data === null || data === '') return null;
  return CryptoJS.AES.encrypt(data, key).toString();
}

function decryptAES(data, key = process.env.VUE_APP_ENCRYPT_KEY.toString()) {
  if (data === null || data === '') return null;
  const bytes = CryptoJS.AES.decrypt(data, key);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export { decryptData, encryptAES, decryptAES };
