var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-3"},[_c('v-row',{staticClass:"mt-4 mb-0 d-flex justify-end"},[_c('v-col',{staticClass:"py-0 d-flex",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{staticClass:"mr-4",attrs:{"dense":"","hide-details":"","items":_vm.listAnios,"no-data-text":"No se encontró información","item-text":"text","item-value":"value","label":"Año"},on:{"change":_vm.cambioAnio},model:{value:(_vm.anio),callback:function ($$v) {_vm.anio=$$v},expression:"anio"}}),_c('v-select',{attrs:{"dense":"","multiple":"","items":_vm.itemsAreas,"no-data-text":"No se encontró información","return-object":false,"item-text":"text","item-value":"value","label":"Área"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.selectAllAreas,"mousedown":function($event){$event.preventDefault();}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.iconCheckboxAllAreas))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" TODOS ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.areas.length == _vm.itemsAreas.length)?[(index === 0)?_c('span',[_vm._v("TODOS")]):_vm._e()]:(_vm.areas.length > 1)?[(index === 0)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("("+_vm._s(_vm.areas.length)+" Items)")]):_vm._e()]:[(index > 0)?_c('span',[_vm._v(",")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.value))])]]}}]),model:{value:(_vm.areas),callback:function ($$v) {_vm.areas=$$v},expression:"areas"}})],1)],1),_c('v-data-table',{staticClass:"border",attrs:{"dense":"","headers":_vm.headers,"height":450,"items":_vm.proyectosFilters,"multi-sort":"","mobile-breakpoint":0,"items-per-page":-1,"no-results-text":"No se encontraron datos","no-data-text":"No se encontraron datos","search":_vm.search,"item-class":"class","fixed-header":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-space-between align-center pa-2"},[_c('div',{staticClass:"black--text"},[_vm._v("PROYECTOS")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-text-field',{staticClass:"mt-0 text-field-search-matriz",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscar","background-color":"rgb(220, 220, 220, 0.16)","rounded":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-export'),expression:"'gh-proy-mis_proy-export'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","small":""},on:{"click":_vm.exportarbase}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("Exportar BD")])])],1)]},proxy:true},{key:"item.proyecto",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.proyecto)+" ")])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex py-0 px-0",attrs:{"cols":"12"}},[_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-delete'),expression:"'gh-proy-mis_proy-delete'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"fab":"","icon":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),(item.editar == 1)?_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-edit'),expression:"'gh-proy-mis_proy-edit'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"fab":"","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.editProyecto(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(item.estado == 2)?_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-sendemail'),expression:"'gh-proy-mis_proy-sendemail'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"fab":"","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.fileSend(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-send ")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar")])]):_vm._e(),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-view'),expression:"'gh-proy-mis_proy-view'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"fab":"","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.ganttView(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-file-tree ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Gantt")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-view'),expression:"'gh-proy-mis_proy-view'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.viewProyecto(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])],1)]}},{key:"item.fechainicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fechainicio .split('-') .reverse() .join('-'))+" ")]}},{key:"item.fechafin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fechafin .split('-') .reverse() .join('-'))+" ")]}}],null,true)}),(_vm.isProyectoView)?_c('ProyectoView',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-view'),expression:"'gh-proy-mis_proy-view'"}],ref:"refproyectoview",attrs:{"object":_vm.objectViewProyecto,"objtable":_vm.objectViewProyectoTable,"dialog":_vm.dialogProView},on:{"closeProyectoView":_vm.closeProyectoView}}):_vm._e(),(_vm.isProyectoEdit)?_c('ProyectoFormulario',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-view'),expression:"'gh-proy-mis_proy-view'"}],ref:"refproyectoedit",attrs:{"id":_vm.idEdit,"object":_vm.objectEditProyecto,"objtable":_vm.objectEditProyectoTable,"dialog":_vm.dialogProEdit},on:{"closeProyectoEdit":_vm.closeProyectoEdit}}):_vm._e(),(_vm.isGanttView)?_c('GanttView',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-proy-mis_proy-view'),expression:"'gh-proy-mis_proy-view'"}],ref:"refelastic",attrs:{"obj":_vm.headerTask,"dialog":_vm.dialogGanttView},on:{"closeGanttView":_vm.closeGanttView}}):_vm._e(),(_vm.showLoading)?_c('div',{staticClass:"progress-background"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }